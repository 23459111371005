// Navigation Bar SECTION
const navBar = {
  show: true,
};

// Main Body SECTION
const mainBody = {
  gradientColors: "#4484ce, #1ad7c0, #ff9b11, #9b59b6, #ff7f7f, #ecf0f1",
  firstName: "Kunal",
  middleName: "",
  lastName: "Mewara",
  message: " Passionate Front End Developer dedicated to revolutionizing the UI landscape with cutting-edge technologies. ",
  icons: [
    {
      image: "fa-github",
      url: "https://github.com/kunal2528",
    },
    {
      image: "fa-facebook",
      url: "https://www.facebook.com/kunal.mewara",
    },
    {
      image: "fa-instagram",
      url: "https://www.instagram.com/_kunal________",
    },
    {
      image: "fa-linkedin",
      url: "https://www.linkedin.com/in/kunal-mewara-040649173",
    },
    {
      image: "fa-twitter",
      url: "https://www.twitter.com/kunalmewara01/",
    },
    {
      image: "fa-gitlab",
      url: "https://gitlab.com/kunalmewara",
    },
  ],
};

const about = {
  show: true,
  heading: "About Me",
  imageLink: require("../editable-stuff/kunalmewara.jpg"),
  imageSize: 375,
  message:
    "My enthusiasm lies in contributing to the community, and I am determined to channel this passion into the realm of software engineering. As a passionate Front End Developer, I seamlessly blend design artistry with programming prowess in a dynamic production environment. Thriving on crafting immersive user experiences, I specialize in efficient website development, proactive feature optimization, and conquering relentless debugging challenges. My journey is fueled by a commitment to not only contribute to the technological landscape but also to elevate the user's digital experience.",
  resume: "https://drive.google.com/file/d/12-dfQZp9kLWLN5zY7wx-e6f323PYt0J1/view?usp=sharing",
};

const repos = {
  show: true,
  heading: "Recent Projects",
  gitHubUsername: "kunal2528",
  reposLength: 10,
  specificRepos: [],
};

// Leadership SECTION
const leadership = {
  show: false,
  heading: "Leadership",
  message:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vitae auctor eu augue ut lectus arcu bibendum at varius. Libero justo laoreet sit amet cursus sit amet. Imperdiet dui accumsan sit amet nulla facilisi morbi. At auctor urna nunc id. Iaculis urna id volutpat lacus laoreet non curabitur gravida. Et magnis dis parturient montes nascetur ridiculus mus mauris. In nisl nisi scelerisque eu ultrices vitae auctor. Mattis nunc sed blandit libero volutpat sed cras ornare. Pulvinar neque laoreet suspendisse interdum consectetur libero.",
  images: [
    { 
      img: require("../editable-stuff/kunalmewara.png"), 
      label: "First slide label", 
      paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum." 
    },
    { 
      img: require("../editable-stuff/kunalmewara.png"), 
      label: "Second slide label", 
      paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum." 
    },
  ],
  imageSize: {
    width:"615",
    height:"450"
  }
};

// SKILLS SECTION
const skills = {
  show: true,
  heading: "Skills",
  hardSkills: [
    { name: "React.js / Next.js", value: 75 },
    { name: "Axios/API Integrations", value: 90 },
    { name: "JavaScript", value: 80 },
    { name: "HTML5/CSS3", value: 85 },
    { name: "GIT", value: 80 },
    { name: "Redux / Context API", value: 65 },
    { name: "Data Structures", value: 70 },
    { name: "Material UI/Bootstrap/Tailwind CSS", value: 75 },
  ],
  softSkills: [
    { name: "Goal-Oriented", value: 95 },
    { name: "Collaboration", value: 90 },
    { name: "Positivity", value: 75 },
    { name: "Adaptability", value: 85 },
    { name: "Problem Solving", value: 75 },
    { name: "Empathy", value: 90 },
    { name: "Organization", value: 70 },
    { name: "Creativity", value: 90 },
  ],
};

// GET IN TOUCH SECTION
const getInTouch = {
  show: true,
  heading: "Get In Touch",
  message:
    "Front-end alchemist: Turning design into code gold. Please feel free to email me at",
  email: "kunalmewara01@gmail.com",
};

const experiences = {
  show: false,
  heading: "Experiences",
  data: [
    {
      role: 'Software Engineer',
      companylogo: require('../assets/img/dell.png'),
      date: 'June 2018 – Present',
    },
    {
      role: 'Front-End Developer',
      companylogo: require('../assets/img/boeing.png'),
      date: 'May 2017 – May 2018',
    },
  ]
}

// Blog SECTION
// const blog = {
//   show: false,
// };

export { navBar, mainBody, about, repos, skills, leadership, getInTouch, experiences };
