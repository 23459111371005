import React from 'react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

const VerticalTimelineComponent = () => {
  return (
    <div style={{background: '#000'}}>
      <VerticalTimeline
        animate={true}
        lineColor='gray'
      >
        <VerticalTimelineElement
          contentStyle={{ background: '#0B0705', color: '#fff', borderBottom: 'none !important' }}
          contentArrowStyle={{ borderRight: '7px solid  #fff' }}
          date="April, 2023 - present"
          iconStyle={{ background: '#0B0705', color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          icon={<i className={"fa fa-briefcase fa-2x"} />}
        >
          <h3 className="vertical-timeline-element-title">Front End Developer</h3>
          <h5 className="vertical-timeline-element-subtitle">QuickVideo.ai, Pune</h5>
          <p>
            React JS, MUI, Axios, Context API, Git, React libraries
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          contentStyle={{ background: '#0B0705', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  #fff' }}
          date="Aug, 2022 - 2023"
          iconStyle={{ background: '#0B0705', color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          icon={<i className={"fa fa-briefcase fa-2x"} />}
        >
          <h3 className="vertical-timeline-element-title">Front End Developer</h3>
          <h5 className="vertical-timeline-element-subtitle">Applied AI Consulting, Pune</h5>
          <p>
            Jest, React JS, Redux, Git, React libraries, Bootstrap, Axios, 
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          contentStyle={{ background: '#0B0705', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  #fff' }}
          date="May, 2021 - August, 2022"
          iconStyle={{ background: '#0B0705', color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          icon={<i className={"fa fa-briefcase fa-2x"} />}
        >
          <h3 className="vertical-timeline-element-title">System Engineer</h3>
          <h5 className="vertical-timeline-element-subtitle">Atos, Pune</h5>
          <p>
            Code Bug fixing, Security Logs, Network Integrity
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          contentStyle={{ background: '#0B0705', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  #fff' }}
          date="2016 - 20"
          iconStyle={{ background: '#0B0705', color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          icon={<i class="fa fa-graduation-cap fa-2x" />}
        >
          <h3 className="vertical-timeline-element-title">B.E. (Computer)</h3>
          <h4 className="vertical-timeline-element-subtitle">D.Y. Patil School of Engineering, Pune</h4>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          contentStyle={{ background: '#0B0705', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  #fff' }}
          date="2015 - 16"
          iconStyle={{ background: '#0B0705', color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          icon={<i class="fa fa-school fa-2x" />}
        >
          <h3 className="vertical-timeline-element-title">H.S.C</h3>
          <h4 className="vertical-timeline-element-subtitle">K.S. Lodha Public Senior Secondary School, Rajasthan</h4>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          contentStyle={{ background: '#0B0705', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  #fff' }}
          date="2013 - 14"
          iconStyle={{ background: '#0B0705', color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          icon={<i class="fa fa-school fa-2x" />}
        >
          <h3 className="vertical-timeline-element-title">S.S.C</h3>
          <h4 className="vertical-timeline-element-subtitle">K.S. Lodha Public Senior Secondary School, Rajasthan</h4>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  )
}

export default VerticalTimelineComponent